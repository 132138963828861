import {
  mdiDesktopMac,
  mdiBullhorn,
  mdiFile,
  mdiNewspaperVariant,
  mdiAccountGroup,
  mdiFileDocumentOutline, mdiBookmark, mdiHorse, mdiHoopHouse
} from '@mdi/js'

export default [
  'General',
  [
    // {
    //   to: '/admin',
    //   icon: mdiDesktopMac,
    //   label: 'Dashboard',
    //   permission:['page_dashboard']
    // },
    {
      to: '/admin/admin-users',
      icon: mdiAccountGroup,
      label: 'Admin-Users',
      permission:['page_users']
    },
    {
      to: '/admin/issues',
      icon: mdiNewspaperVariant,
      label: 'Manage Issues',
      permission:['page_issues']
    },
    {
      label: "Photo Gallery",
      icon: mdiFileDocumentOutline,
      permission:['page_photos'],
      menu: [
        {
          to: '/admin/gallery/albums',
          label: 'Albums'
        },
        {
          to: '/admin/gallery/photographers',
          label: 'Photographers'
        },
      ]
    },
    {
      label: 'Horse Show Results',
      subLabel: 'Horse Show Results',
      icon: mdiBullhorn,
      permission:['result_breed','result_division','result_classes','result_aaad','result_horses','result_owners','result_riders','result_results'],
      menu: [
        {
          to: '/admin/results/breeds',
          label: 'Breeds',
          permission:['result_breed'],
        },
        {
          to: '/admin/results/divisions',
          label: 'Divisions',
          permission:['result_division'],
        },
        {
          to: '/admin/results/classes',
          label: 'Classes',
          permission:['result_classes'],
        },
        {
          to: '/admin/results/aaads',
          label: 'All-American Award Division',
          permission:['result_aaad'],
        },
        {
          to: '/admin/results/horses',
          label: 'Horses',
          permission:['result_horses'],
        },
        {
          to: '/admin/results/stables',
          label: 'Stables'
        },
        {
          to: '/admin/results/owners',
          label: 'Owners',
          permission:['result_owners'],
        },
        {
          to: '/admin/results/riders',
          label: 'Riders',
          permission:['result_riders'],
        },
        {
          to: '/admin/results',
          label: 'Results',
          permission:['result_results'],
        },
        {
          to: '/admin/results/final-form',
          label: 'Final form order',
          permission:['result_results'],
        },
      ]
    },
    {
      label: 'Pages',
      subLabel: 'Pages',
      icon: mdiBullhorn,
      permission:['page_homepage','page_news','page_results','page_calendar','page_videos','page_advertisers','page_employment','page_about'],
      menu: [
        {
          label: 'Homepage',
          permission:['page_homepage'],
          menu: [ 
            {
            to: '/admin/pages/homepage/current-issue',
            label: 'Current Issue/Featured Event'
            },
            {
              to: '/admin/pages/homepage/widgets',
              label: 'Widget'
            },
            {
              to: '/admin/pages/homepage/slider',
              label: 'Slider'
            }
          ]
        },
        {
          to: '/admin/pages/news',
          label: 'News',
          permission:['page_news'],
        },
        {
          label: 'Results',
          permission:['page_results'],
          menu: [
            {
              to: '/admin/pages/results/all-american-standings',
              label: 'All-American Standings'
            },
            {
              to: '/admin/pages/results/sire-standings',
              label: 'Sire Standings'
            }
          ]
        },
        {
          to: '/admin/pages/calendar',
          label: 'Calendar',
          permission:['page_calendar'],
        },
        {
          to: '/admin/pages/subscribe',
          label: 'Subscribe',
        },
        {
          to: '/admin/pages/videos',
          label: 'Videos',
          permission:['page_videos'],
        },
        {
          label: 'Advertise',
          permission:['page_advertisers'],
          menu: [
            {
              to: '/admin/pages/advertise/categories',
              label: 'Categories'
            },
            {
              to: '/admin/pages/advertise/testimonials',
              label: 'Testimonials'

            }
          ]
        },
        {
          to: '/admin/pages/advertisers',
          label: 'Advertisers',
          permission:['page_advertisers'],
        },
        {
          to: '/admin/pages/employment',
          label: 'Employment',
          permission:['page_employment'],
        },
        {
          label: 'About',
          permission:['page_about'],
          menu: [
            {
              to: '/admin/pages/about/our-team',
              label: 'Our Team'
            },
            {
              to: '/admin/pages/about/our-history',
              label: 'Our History'
            }
            ],
        },
        {
          to: '/admin/pages/terms-conditions',
          label: 'Terms & Conditions',
        },
      ]
    },
    {
      label: 'Directory',
      icon: mdiHoopHouse,
      permission:['page_directory'],
      menu: [
        {
          to: '/admin/directory/farms-and-stables',
          label: 'Farms and Stables'
        },
        {
          to: '/admin/directory/products-and-services',
          label: 'Products and services'
        },
      ]
    },
    {
      label: 'Horse Listings',
      icon: mdiHorse,
      permission:['page_horse'],
      menu: [
        {
          to: '/admin/horse-listings',
          label: 'For Sale'
        },
        {
          to: '/admin/horse-listings/stallions',
          label: 'Stallions'
        },
      ]
    },
    {
      label: "Banners",
      icon: mdiBookmark,
      permission:['page_banners'],
      menu: [
        {
          to: '/admin/banners/banner-list',
          label: 'Banner list'
        },
        {
          to: '/admin/banners/page-options-banners',
          label: 'Banner page options'
        },
      ]
    },
    {
      to: '/admin/crm',
      icon: mdiAccountGroup,
      label: 'CRM',
      permission:['page_crm','page_users'],
    },
    {
      to: '/admin/file-manager',
      icon: mdiFile,
      label: 'File Manager',
      permission:['page_crm','page_users'],
    },
    {
      label: "Orders",
      icon: mdiFileDocumentOutline,
      permission:['page_orders','page_subscriptions'],
      menu: [
        {
          to: '/admin/orders',
          label: 'Transactions',
          permission:['page_orders'],
        },
        {
          to: '/admin/subscriptions',
          label: 'Subscriptions',
          permission:['page_subscriptions'],
        },
      ]
    },
    {
      label: "Definitions",
      icon: mdiFileDocumentOutline,
      permission:['page_defissues','page_taxes','page_offers'],
      menu: [
        {
          to: '/admin/definitions/issue-plan',
          label: 'Issue Plan',
          permission:['page_defissues'],
        },
        {
          to: '/admin/definitions/taxes',
          label: 'Taxes',
          permission:['page_taxes'],
        },
        {
          to: '/admin/definitions/offers',
          label: 'Offers',
          permission:['page_offers'],
        },
      ]
    },
    {
      label: "Reports",
      icon: mdiFileDocumentOutline,
      permission:['report_subscriptions','report_ecommerce','report_results','report_all_american_awards','report_sire_standings','page_crm','page_users'],
      menu: [
        {
          label: "Subscriptions",
          icon: mdiFileDocumentOutline,
          permission:['report_subscriptions'],
          menu: [
            {
              to: '/admin/reports/subscriptions/print',
              label: 'Print Subscribers'
            },
            {
              to: '/admin/reports/subscriptions/digital',
              label: 'Digital Subscribers'
            },
            {
              to: '/admin/reports/subscriptions/horse',
              label: 'Horse Listing Subscriptions'
            },
            {
              to: '/admin/reports/subscriptions/banner',
              label: 'Banners Subscriptions'
            },
            {
              to: '/admin/reports/subscriptions/directory',
              label: 'Directory Listing Subscriptions'
            },
          ]
        },
        {
          label: "E-Commerce",
          icon: mdiFileDocumentOutline,
          permission:['report_ecommerce'],
          role:['Superadmin'],
          menu: [
            {
              to: '/admin/reports/photos',
              label: 'Photos'
            },
            {
              to: '/admin/reports/revenue',
              label: 'Revenue',
              role:['Superadmin'],
            }
          ]
        },
        {
          label: "Results",
          icon: mdiFileDocumentOutline,
          permission:['report_results'],
          menu: [
            {
              to: '/admin/reports/results',
              label: 'Results'
            },
            {
              to: '/admin/reports/riders',
              label: 'Riders'
            },
            {
              to: '/admin/reports/rider-points',
              label: 'Rider Points'
            }
          ]
        },
        {
          label: "All-American Awards",
          icon: mdiFileDocumentOutline,
          permission:['report_all_american_awards'],
          menu: [
            {
              to: '/admin/reports/all-american-awards',
              label: 'All-American Awards'
            },
            {
              to: '/admin/reports/owners',
              label: 'Owners'
            }
          ]
        },
        {
          label: "Sire Standings",
          icon: mdiFileDocumentOutline,
          permission:['report_sire_standings'],
          menu: [
            {
              to: '/admin/reports/sire',
              label: 'Sire'
            }
          ]
        },
        {
          label: "Users",
          icon: mdiFileDocumentOutline,
          permission:['page_crm','page_users'],
          menu: [
            {
              to: '/admin/reports/users',
              label: 'Users Report'
            }
          ]
        },
      ]
    },
    {
      label: "Campaigns",
      icon: mdiAccountGroup,
      permission: ['page_orders'],
      to: '/admin/campaigns',
    },
  ]
]
