<script setup>
import {computed, onMounted, watch} from 'vue'
import { useMainStore } from '~/stores/main'
import menu from '~/utils/menu'
import NavBar from '@/components/Admin/NavBar.vue'
import AsideMenu from '@/components/Admin/AsideMenu.vue'
import Overlay from '@/components/Admin/Overlay.vue'
import {http} from "~/utils/http";
import {mdiAlertCircle} from '@mdi/js'
import {navigateTo} from "nuxt/app";

const mainStore = useMainStore()
const message = computed(()=>mainStore.message);
const overlay = computed(()=>mainStore.overlay);
const authorized = computed(() => mainStore.authorized)
const isAsideMobileExpanded = computed(() => mainStore.isAsideMobileExpanded)
const getUser = async() =>{

  try{
    await http.get('/api/checkUser').then((response) =>{
      mainStore.setUser({
        name: response.data.name,
        email: response.data.email,
        permissions: response.data.permissions,
        roles: response.data.roles,
        avatar: 'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93'
      })
    })
  }catch (e){
    navigateTo('/admin/login')
  }
}

const isAsideLgActive = computed(() => mainStore.isAsideLgActive)

const overlayClick = () => {
  mainStore.asideLgToggle(false)
}

onMounted( ()=> {
  mainStore.setStyle(localStorage.getItem('style')?? 'basic');
  if(localStorage.getItem('darkMode') > 0) {
    mainStore.setDarkMode(true);
  }
  else{
    mainStore.setDarkMode(false);
  }
   getUser();

})

watch(message, value => {
  if(value !== ''){
    window.scrollTo(0, 0);
    setTimeout(() => {
      mainStore.setMessage('');
    }, 5000);
  }
})

watch(overlay, value => {
  if(value){
    window.scrollTo(0, 0);
  }
})

watch(authorized, value => {
  if(!authorized.value){
    mainStore.isAuthorization(true);
    navigateTo('/admin/unauthorized')
  }
})

onMounted(()=>{
  mainStore.asideMobileToggle();
})
</script>

<template>

  <div id="admin"  :class="isAsideMobileExpanded ? 'lg:pl-60':''">
  <nav-bar/>
  <aside-menu :menu="menu" />
    <template v-if="message">
        <admin-notification
            color="success"
            :icon="mdiAlertCircle"
        >
          {{ message }}
        </admin-notification>
    </template>
  <div class="h-100">
    <slot>

    </slot>
  </div>
<!--  <footer-bar />-->
  <overlay
      v-show="overlay"
      z-index="z-30"
      @overlay-click="overlayClick"
  >
    <div class="half-circle-spinner">
      <div class="circle circle-1"></div>
      <div class="circle circle-2"></div>
    </div>
  </overlay>
  </div>
</template>
<style>
body {
  padding-right: 0 !important;
}

#admin{
  min-height: 100vh;
}

.half-circle-spinner, .half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);

  }
  100%{
    transform: rotate(360deg);
  }
}
</style>
